import { Badge, HStack, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getPhysicalMailAddress, ORGANIZATION_SERVICE_QUERY_KEYS } from 'apis/organizations-apis';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { useOrg } from 'hooks/useOrg';
import { BillingPlanEnum } from 'schema/types-schema.d';
import { PhysicalMailStatus } from 'types/shared-types';

import { SetupPhysicalMailModal } from './setup-physical-mail-modal';

export const PhysicalMail = () => {
  const { orgId, isTest } = useOrg();
  const { billingPlan } = usePaywall();
  const { open, onOpen, onClose } = useDisclosure();

  const { data: physicalMailData, isLoading } = useQuery({
    queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.physicalMail(orgId)],
    queryFn: async () => {
      const res = await getPhysicalMailAddress(orgId);
      return res?.data;
    },
    select: data => {
      if (!Array.isArray(data)) return undefined;

      const addressData = data[0];
      const physicalStatus = data[1];

      if (Object.values(addressData).every(value => value === null)) {
        return undefined;
      }

      return { ...addressData, physicalStatus };
    },
    enabled: billingPlan != BillingPlanEnum.FREE && !isTest,
  });

  if (isLoading) {
    return <Skeleton height="82px" width="full" />;
  }

  const getBadge = (status: string) => {
    switch (status) {
      case PhysicalMailStatus.authorize:
      case PhysicalMailStatus.sign:
        return (
          <Badge colorPalette={'gray'} fontWeight={'400'}>
            Incomplete
          </Badge>
        );
      case PhysicalMailStatus.verify:
        return (
          <Badge colorPalette={'blue'} fontWeight={'400'}>
            Verifying
          </Badge>
        );
      case PhysicalMailStatus.complete:
        return (
          <Badge colorPalette={'green'} fontWeight={'400'}>
            Active
          </Badge>
        );
      default:
        return null;
    }
  };

  const getStatusText = (status: string, physicalMailData: any) => {
    switch (status) {
      case PhysicalMailStatus.authorize:
      case PhysicalMailStatus.sign:
        return 'Please complete the remaining steps to set up the physical mail address.';
      case PhysicalMailStatus.verify:
        return 'Your physical mail address will be available once we verify your details.';
      case PhysicalMailStatus.complete:
        return (
          physicalMailData?.line1 +
          ', ' +
          physicalMailData?.line2 +
          ', ' +
          physicalMailData?.city +
          ', ' +
          physicalMailData?.state +
          ', ' +
          physicalMailData?.postal_code
        );
      default:
        return null;
    }
  };

  const showButton =
    !isLoading &&
    !isTest &&
    (!physicalMailData ||
      ![PhysicalMailStatus.complete, PhysicalMailStatus.verify].includes(physicalMailData.physicalStatus));

  return (
    <>
      <HStack
        w="100%"
        border="1px solid #ddd"
        p={4}
        borderRadius={4}
        justify={'space-between'}
        flexWrap={{ base: 'nowrap', md: 'nowrap', sm: 'wrap' }}
      >
        <Stack flexDirection={'column'}>
          <HStack flexWrap={'wrap'}>
            <Text fontSize="md" fontWeight="medium">
              Physical Mail Address
            </Text>
            {physicalMailData?.physicalStatus && getBadge(physicalMailData?.physicalStatus)}
          </HStack>

          {!physicalMailData ? (
            <Text fontSize="sm">Please click on &apos;Setup&apos; to set up a physical mail address.</Text>
          ) : (
            <Text fontSize="sm">{getStatusText(physicalMailData?.physicalStatus, physicalMailData)}</Text>
          )}
        </Stack>

        <Stack align={'center'}>
          <Button
            fontSize={'sm'}
            fontWeight={500}
            width="120px"
            onClick={onOpen}
            variant={'solid'}
            hidden={!showButton}
          >
            {[PhysicalMailStatus.authorize, PhysicalMailStatus.sign].includes(physicalMailData?.physicalStatus)
              ? 'Complete Form'
              : 'Setup'}
          </Button>
        </Stack>
      </HStack>
      {open && <SetupPhysicalMailModal isOpen={open} onClose={onClose} />}
    </>
  );
};
