import { Card, Flex, Input, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addOrUpdateBankDetails, BANK_DETAILS_STATE_KEY, getBankDetails } from 'apis/bank-details-apis';
import { ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { useACL } from 'app/acl/acl';
import { SecuredView } from 'components/secured-view';
import { Button } from 'components/ui/button';
import { Field } from 'components/ui/field';
import { Radio, RadioGroup } from 'components/ui/radio';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useEffect, useState } from 'react';
import { getMaxLengthMessage } from 'utils/utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  account_holder_name: Yup.string().required('Account Name is required'),
  account_type: Yup.string().required('Account Type required'),
  bank_name: Yup.string().required('Bank Name is required').max(100, getMaxLengthMessage('Bank Name', 100)),
  routing_number: Yup.string().required('Routing Number is required'),
  account_number: Yup.string().required('Account Number is required'),
  account_number_confirmation: Yup.string().oneOf([Yup.ref('account_number')], 'Account Number must match'),
});

const BankDetails = () => {
  const { orgId } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const { track } = useTracking();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return addOrUpdateBankDetails(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BANK_DETAILS_STATE_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId] });
      handleSuccessNotification('Bank Details successfully updated');
    },
  });

  const [isSecure, setIsSecure] = useState(true);
  const {
    data: bankDetails,
    isPending,
    isSuccess,
  } = useQuery({
    queryKey: [BANK_DETAILS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getBankDetails(orgId);
      return data;
    },
    enabled: !!orgId && isOwner,
  });

  useEffect(() => {
    if (isSuccess && !bankDetails) {
      setIsSecure(false);
    }
  }, [isSuccess, bankDetails]);

  const {
    values: {
      account_holder_name,
      account_type,
      bank_name,
      routing_number,
      account_number,
      account_number_confirmation,
    },
    errors,
    touched,
    isValid,
    dirty,
    handleBlur,
    handleChange,
    setTouched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      account_holder_name: bankDetails?.account_holder_name ?? '',
      account_type: bankDetails?.account_type ?? 'CHECKING',
      bank_name: bankDetails?.bank_name ?? '',
      routing_number: bankDetails?.routing_number ?? '',
      account_number: bankDetails?.account_number ?? '',
      account_number_confirmation: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
      track('fills_complete Bank Details');
    },
  });

  const isAccountHolderNameInvalid = !!(errors.account_holder_name && touched.account_holder_name);
  const isAccountTypeInvalid = !!(errors.account_type && touched.account_type);
  const isBankNameInvalid = !!(errors.bank_name && touched.bank_name);
  const isRoutingNumberInvalid = !!(errors.routing_number && touched.routing_number);
  const isAccountNumberInvalid = !!(errors.account_number && touched.account_number);
  const isAccountNumberConfirmationInvalid = !!(
    touched.account_number_confirmation &&
    (errors.account_number_confirmation || account_number !== account_number_confirmation)
  );

  return (
    <Skeleton loading={isPending}>
      <Card.Root maxW={{ base: '100%', sm: '100%', md: '60%' }}>
        <Card.Body>
          <Text fontSize={'1rem'} fontWeight="500">
            Bank Account Information
          </Text>
          <Text fontSize={'0.875rem'} mb={'16px'}>
            These bank details are not used to pay for Kintsugi services, these are only used to remit payment for
            filings or registrations. We will never process a payment without your confirmation.
          </Text>

          <SecuredView
            containerProps={{ p: isSecure ? 2 : undefined }}
            isSecured={isSecure}
            handlePreview={() => setIsSecure(false)}
          >
            <SimpleGrid columns={{ base: 2, md: 2, sm: 1 }} gap={5}>
              <Field
                label="Account Holder Name"
                errorText={errors.account_holder_name}
                invalid={isAccountHolderNameInvalid}
                required
              >
                <Input
                  id="account_holder_name"
                  type="account_holder_name"
                  name="account_holder_name"
                  value={account_holder_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Field>
              <Field label="Account Type" invalid={isAccountTypeInvalid} errorText={errors.account_type} required>
                <RadioGroup name="account_type" defaultValue={'CHECKING'} value={account_type}>
                  <Stack gap={[1, 5]} direction={['column', 'row']}>
                    <Radio value="CHECKING" onChange={handleChange} onBlur={handleBlur}>
                      Checking
                    </Radio>
                    <Radio value="SAVINGS" onChange={handleChange} onBlur={handleBlur}>
                      Savings
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Field>
              <Field label="Bank Name" invalid={isBankNameInvalid} errorText={errors.bank_name} required>
                <Input
                  id="bank_name"
                  type="bank_name"
                  name="bank_name"
                  value={bank_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Field>
              <Field label="Routing Number" invalid={isRoutingNumberInvalid} errorText={errors.routing_number} required>
                <Input
                  id="routing_number"
                  type="routing_number"
                  name="routing_number"
                  value={routing_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Field>
              <Field label="Account Number" invalid={isAccountNumberInvalid} errorText={errors.account_number} required>
                <Input
                  id="account_number"
                  type="account_number"
                  name="account_number"
                  value={account_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Field>

              {!bankDetails?.id && (
                <Field
                  label="Confirm Account Number"
                  invalid={isAccountNumberConfirmationInvalid}
                  errorText={errors.account_number_confirmation}
                  required
                >
                  <Input
                    id="account_number_confirmation"
                    type="account_number_confirmation"
                    name="account_number_confirmation"
                    value={account_number_confirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Field>
              )}
            </SimpleGrid>
          </SecuredView>

          <Flex justify={'flex-end'} align={'flex-end'} mt={!bankDetails?.id ? 4 : 0}>
            <Button
              loading={mutation.isPending}
              disabled={!isValid || !dirty || (!bankDetails?.id && !account_number_confirmation)}
              width={'7rem'}
              onClick={() => {
                if (isValid) {
                  handleSubmit();
                } else {
                  setTouched({
                    account_holder_name: true,
                    account_type: true,
                    bank_name: true,
                    routing_number: true,
                    account_number: true,
                    account_number_confirmation: true,
                  });
                }
              }}
            >
              Save
            </Button>
          </Flex>
        </Card.Body>
      </Card.Root>
    </Skeleton>
  );
};

export default BankDetails;
