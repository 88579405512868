import { Flex, Input, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveOrg } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { Organization, setOrg, useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import useTracking from 'hooks/useTracking';
import { object, string } from 'yup';

const validationSchema = object().shape({
  orgName: string().required('Organization name is required'),
});

type ArchiveOrganizationFormProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ArchiveOrganizationForm = ({ isOpen, onClose }: ArchiveOrganizationFormProps) => {
  const { orgId, name, externalId } = useOrg();
  const { track } = useTracking();
  const [, setSearchParams] = useQueryParam('archiveOrg');
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (values: { orgName: string }) => {
      return await archiveOrg(orgId, values.orgName);
    },
    onSuccess: () => {
      handleSuccessNotification(
        'Archiving request successfully submitted. Contact support within 30 days if you want to undo this action.'
      );
      track('archived organization');
      queryClient.invalidateQueries({ queryKey: ['partner-orgs'] });
      onClose();
      setSearchParams({ archiveOrg: externalId });
      setOrg({} as Organization);
    },
  });

  const formik = useFormik({
    initialValues: {
      orgName: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      size={'md'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to archive organization?</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>
            Once you archive an organization, all connections will be disabled and the organization will be scheduled
            for deletion after 30 days. You can contact support during this period to reverse this.
          </Text>
          <Text mt={6}>
            Please enter the name of the organization <strong>{name}</strong> to confirm that you want to archive it.
          </Text>
          <form onSubmit={formik.handleSubmit} id="archive-org-form">
            <Field
              label="Enter Organization Name"
              invalid={!!(formik.errors.orgName && formik.touched.orgName)}
              errorText={formik.errors.orgName}
              required
              mt={5}
            >
              <Input
                id="orgName"
                type="text"
                value={formik.values.orgName}
                onChange={formik.handleChange}
                name="orgName"
              />
            </Field>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              form="archive-org-form"
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              loading={mutation.isPending}
              type="submit"
              disabled={formik.values.orgName !== name}
            >
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
