import { Flex, Heading, Input, Skeleton, Stack, Table, VStack } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_KEYS_STATE, deleteAPIKey, getAPIKeysByOrgId } from 'apis/organizations-apis';
import { useACL } from 'app/acl/acl';
import { LinkIcon, SearchIcon } from 'components/icons';
import { PaginationButtons } from 'components/pagination/pagination';
import { KDataTable } from 'components/table/data-table';
import { Button } from 'components/ui/button';
import { InputGroup } from 'components/ui/input-group';
import { useApiKeysFilter } from 'hooks/useApiKeysFilter';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { APIKeysResponse } from 'types/organizations';

import { APIKeyDeleteConfirmationModal } from './api-key-delete-confimration-modal';
import { APIKeyModal } from './api-key-modal';
import { APIKeyTableRow } from './api-key-table-row';

const TABLE_HEADERS = ['Key', 'Created', 'Expires'];

export const APIKeys = () => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [, setSearchParams] = useSearchParams();
  const { pageSize, pageNumber, active, setFilters } = useApiKeysFilter();
  const { data: apiKeys, isLoading: isAPIKeysPending } = useQuery<APIKeysResponse>({
    queryKey: [API_KEYS_STATE, orgId, { pageSize, pageNumber, active }],
    queryFn: async () => {
      const { data } = await getAPIKeysByOrgId(orgId, { pageSize, pageNumber, active });
      return data;
    },
    enabled: !!orgId && isOwner,
  });
  const currentPageUI = pageNumber + 1;
  const totalPagesUI = apiKeys?.has_more_results ? pageNumber + 2 : pageNumber + 1;
  const handlePageSizeChange = (page: number, size: number) => {
    setFilters({ pageNumber: page - 1, pageSize: size });
  };

  const { mutateAsync, isPending: isDeleteApiKeyIsPending } = useMutation({
    mutationFn: (apiKeyId: string) => deleteAPIKey(orgId, apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_KEYS_STATE, orgId, { pageSize, pageNumber, active }] });
      handleSuccessNotification('API Key successfully deleted.');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchTerm = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value);
  };

  const filteredArray = useMemo(() => {
    return (apiKeys?.api_keys || []).filter(({ api_key_id }) => {
      return api_key_id.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [apiKeys, searchTerm]);
  const isDataEmpty = !filteredArray.length;

  const handleDeleteApiKey = (id: string) => {
    setSearchParams({ deleteApiKey: 'true', apiKeyId: id });
  };

  if (isAPIKeysPending) {
    return (
      <VStack gap={4} mt={4}>
        {Array.from({ length: pageNumber > 1 ? pageSize : 3 }, (_, index) => (
          <Skeleton key={index} height={20} width="full" />
        ))}
      </VStack>
    );
  }

  return (
    <>
      <Flex justify="space-between" align="center" mb="4">
        <Heading as={'h6'} size="xs" fontSize="md">
          API Keys
        </Heading>
        <Stack direction="row" gap={4}>
          <InputGroup startElement={<SearchIcon />}>
            <Input
              value={searchTerm}
              onChange={handleSearchTerm}
              placeholder="Search"
              height={'32px'}
              width={'280px'}
            />
          </InputGroup>
          <Button
            px={2}
            py={1}
            onClick={() =>
              window.open('https://kintsugi.readme.io/reference/search_v1_address_validation_search_post', '_blank')
            }
            variant={'plain'}
            fontSize={'sm'}
            color={'blue.500'}
            fontWeight={500}
            gap={1}
          >
            API Documentation
            <LinkIcon width={3} height={3} />
          </Button>
          <APIKeyModal />
        </Stack>
      </Flex>
      <KDataTable headers={TABLE_HEADERS}>
        {!isDataEmpty &&
          filteredArray.map(({ api_key_id, created_at, expires_at_seconds }) => (
            <APIKeyTableRow
              key={api_key_id}
              id={api_key_id}
              createdAt={created_at}
              expiresAt={expires_at_seconds}
              handleDeleteApiKey={handleDeleteApiKey}
            />
          ))}
        {isDataEmpty && (
          <Table.Row>
            <Table.Cell colSpan={8} textAlign={'center'}>
              There are no api keys in this organization
            </Table.Cell>
          </Table.Row>
        )}
      </KDataTable>
      {!isDataEmpty && (
        <PaginationButtons
          size={pageSize}
          currentPage={currentPageUI}
          totalPages={totalPagesUI}
          onPageClick={handlePageSizeChange}
          onSizeChange={handlePageSizeChange}
        />
      )}
      <APIKeyDeleteConfirmationModal deleteAPIKey={mutateAsync} isDeleteApiKeyIsPending={isDeleteApiKeyIsPending} />
    </>
  );
};
