import './date-picker.css';

import { Flex, HStack, IconButton, Input, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import ReactDatePicker, {
  CalendarContainer,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import { MdChevronLeft, MdChevronRight, MdOutlineCalendarMonth } from 'react-icons/md';
import { adjustTimeZoneOffsetWithUtc, getFormattedDate, isValidDate } from 'utils/dates';

import { NativeSelectField, NativeSelectRoot } from './native-select';

interface CalendarHeaderProps extends ReactDatePickerCustomHeaderProps {}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeMonth,
  changeYear,
}) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const years = Array.from({ length: 20 }, (_, i) => date.getFullYear() - 10 + i);

  return (
    <Flex p={4} pt={1} pb={1} w="100%" justifyContent={'space-between'} align={'center'}>
      <VStack gap={1} align="start">
        <HStack gap={2}>
          <NativeSelectRoot size="sm">
            <NativeSelectField
              value={date.getMonth()}
              onChange={e => changeMonth(parseInt(e.target.value))}
              width="auto"
            >
              {months.map((month, index) => (
                <option key={month} value={index}>
                  {month}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
          <NativeSelectRoot size="sm">
            <NativeSelectField
              value={date.getFullYear()}
              onChange={e => changeYear(parseInt(e.target.value))}
              width="auto"
            >
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </HStack>
      </VStack>
      <HStack gap={2}>
        <IconButton
          size="sm"
          variant={'ghost'}
          aria-label="prev-month"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <MdChevronLeft fontSize={'1.5rem'} color="#000" />
        </IconButton>
        <IconButton
          size="sm"
          aria-label="next-month"
          variant={'ghost'}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <MdChevronRight fontSize={'1.5rem'} color="#000" />
        </IconButton>
      </HStack>
    </Flex>
  );
};

interface DatePickerProps extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
  variant?: string;
  selected?: null | string | number | Date;
  onChange: (date: string) => void;
  returnDateFormat?: string;
  limitToLastDay?: boolean;
}

const defaultProps: Partial<DatePickerProps> = {
  dateFormat: 'MMM dd, yyyy',
  placeholderText: 'Select Date',
  customInput: <Input />,
  renderCustomHeader: CalendarHeader,
  showIcon: true,
  icon: <MdOutlineCalendarMonth />,
};

const DatePicker: React.FC<PropsWithChildren<DatePickerProps>> = ({
  calendarContainer,
  selected = '',
  onChange,
  returnDateFormat = 'yyyy-MM-dd',
  limitToLastDay = false,
  ...rest
}: PropsWithChildren<DatePickerProps>): React.ReactElement => {
  const selectedDate =
    !!selected && isValidDate(new Date(selected)) ? adjustTimeZoneOffsetWithUtc(new Date(selected)) : null;

  const handleOnChange = (date: Date) => {
    const formattedDate = getFormattedDate(date, returnDateFormat);
    onChange(formattedDate);
  };

  const isLastDayOfMonth = (date: Date) => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return date.getDate() === lastDay;
  };

  const DefaultCalendarContainer = ({ className, children }: any) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    );
  };

  return (
    <ReactDatePicker
      {...defaultProps}
      {...rest}
      selected={selectedDate}
      onChange={handleOnChange}
      calendarContainer={calendarContainer ? calendarContainer : DefaultCalendarContainer}
      filterDate={limitToLastDay ? isLastDayOfMonth : undefined}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
    />
  );
};

export default DatePicker;
