import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardTasks } from 'apis/dashboard-apis';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useOrg } from 'hooks/useOrg';

import { ArchiveOrganization } from './archive-org/archive-org';
import { AutoFile } from './org-settings/auto-file';
import { AutoRegister } from './org-settings/auto-register';
import { CreateOrg } from './org-settings/create-org';
import { SettingCard } from './org-settings/setting-card';

export const OrgSettings = () => {
  const { orgId } = useOrg();

  const { data: tasks, isLoading } = useQuery({
    queryKey: ['tasks', orgId],
    queryFn: async () => {
      const { data } = await getDashboardTasks(orgId);
      return data;
    },
    enabled: !!orgId,
  });

  const { data: orgDetails, isPending: isOrgDetailsFetching } = useOrgDetailsQuery(orgId);

  const showTooltip = Boolean(
    (tasks?.invalid_addresses && tasks?.invalid_addresses > 0) || (tasks?.pending_product && tasks?.pending_product > 0)
  );

  const settingOptions = [
    {
      title: 'Auto File',
      subTitle:
        'Automatically approve all the filings on the 10th of the month. You can still approve manually before the filing is auto-approved.',
      actionComponent: <AutoFile autoFile={orgDetails?.auto_file ?? undefined} />,
      showTooltip,
      tooltipText:
        'Please ensure you complete your tasks, such as validating addresses and approving products so that filings can be auto-approved.',
    },
    {
      title: 'Auto Register',
      subTitle: 'Automatically approve registrations as soon as the nexus is met.',
      actionComponent: <AutoRegister autoRegister={orgDetails?.auto_register ?? undefined} />,
      showTooltip,
      tooltipText:
        'Please ensure you complete your tasks, such as validating addresses and approving products so that registrations can be auto-approved.',
    },
    {
      title: 'Archive Organization',
      subTitle:
        'Archive this Organization to remove connections, disable access, and start the process of deleting your data. We will delete the Organization and data after 30 days.',
      actionComponent: <ArchiveOrganization />,
      showTooltip: false,
    },
    {
      title: 'Create Test Organization',
      subTitle:
        'You can create a test organization to integrate with test sites and do feature testing. You can switch between different organizations anytime.',
      actionComponent: <CreateOrg />,
      showTooltip: false,
    },
  ];

  if (isLoading || isOrgDetailsFetching) {
    return null;
  }
  return (
    <Stack gap={4}>
      {settingOptions.map(option => (
        <SettingCard key={option.title.toLocaleLowerCase()} {...option} />
      ))}
    </Stack>
  );
};
