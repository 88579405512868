import { Flex, Heading, Input, Skeleton, Stack, Table, VStack } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getInvitesByOrgId,
  getUsersByOrgId,
  updateUserRole,
  USERS_AND_INVITES_STATE_KEY,
} from 'apis/organizations-apis';
import { useACL } from 'app/acl/acl';
import { SearchIcon } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { InputGroup } from 'components/ui/input-group';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useUsersFilter } from 'hooks/useUsersFilter';
import { useCallback, useMemo, useState } from 'react';
import { ActiveAndInvitedUser, ActiveAndInvitedUserStatus, InviteResponse, User } from 'types/organizations';
import { UserRoleUpdateRequest } from 'types/shared-types';

import { InviteUser } from './invite-user';
import { UserTableRow } from './user-table-row';

const TABLE_HEADERS = ['Email', 'Role', 'Status'];
const TABLE_CENTER_HEADERS = ['Status'];
export const Users = () => {
  const { orgId } = useOrg();
  const { user } = useAuthInfo();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');
  const { pageSize, pageNumber, includeOrgs, role } = useUsersFilter();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isPending } = useQuery<
    { users: User[]; invites: InviteResponse[] },
    unknown,
    { users: ActiveAndInvitedUser[]; usersById: Record<string, ActiveAndInvitedUser> }
  >({
    queryKey: [USERS_AND_INVITES_STATE_KEY, orgId, { pageSize, pageNumber, role, includeOrgs }],
    queryFn: async () => {
      const [usersResponse, invitesResponse] = await Promise.all([
        getUsersByOrgId(orgId, {
          pageSize: pageSize!,
          pageNumber: pageNumber!,
          role,
          includeOrgs,
        }),
        getInvitesByOrgId(orgId, { pageSize, pageNumber }),
      ]);
      return { users: usersResponse.data.users || [], invites: invitesResponse.data.invites || [] };
    },
    select: ({ users, invites }) => {
      const mappedUsers = users.map(({ user_id, email, user_role, enabled, user_permissions }) => ({
        user_id,
        email,
        user_role,
        user_permissions,
        status: enabled ? ActiveAndInvitedUserStatus.ACTIVE : ActiveAndInvitedUserStatus.INACTIVE,
      }));
      const mappedInvites = invites.map(({ invitee_email, role_in_org }) => ({
        user_id: invitee_email,
        email: invitee_email,
        user_role: role_in_org,
        user_permissions: [],
        status: ActiveAndInvitedUserStatus.PENDING,
      }));
      const allUsers = [...mappedUsers, ...mappedInvites] as ActiveAndInvitedUser[];
      const usersById = allUsers.reduce(
        (acc, user) => {
          acc[user.user_id] = user;
          return acc;
        },
        {} as Record<string, ActiveAndInvitedUser>
      );

      return {
        users: allUsers,
        usersById,
      };
    },
    enabled: !!orgId && isOwner,
  });

  const handleSearchTerm = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value);
  };

  const getFilteredArray = useCallback(() => {
    return (data?.users || []).filter(({ email }) => {
      return email.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [data, searchTerm]);

  const filteredArray = useMemo(getFilteredArray, [getFilteredArray]);
  const canChangeRoles = useMemo(() => {
    const user_permissions =
      user?.userId && data?.usersById[user.userId] ? data.usersById[user.userId].user_permissions : [];
    return (user_permissions || []).includes('propelauth::can_change_roles');
  }, [data, user]);

  const { mutate } = useMutation({
    mutationFn: async (payload: UserRoleUpdateRequest) => {
      const { data } = await updateUserRole(orgId, payload);
      return data;
    },
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: [USERS_AND_INVITES_STATE_KEY, orgId],
      });
      handleSuccessNotification(message);
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  if (isPending)
    return (
      <VStack gap={4} mt={4}>
        {Array.from({ length: pageNumber > 1 ? pageSize : 3 }, (_, index) => (
          <Skeleton key={index} height={20} width="full" />
        ))}
      </VStack>
    );

  return (
    <>
      <Flex justify="space-between" align="center" mb="4">
        <Heading as={'h6'} size="xs" fontSize="md">
          Users
        </Heading>
        <Stack direction="row" gap={4}>
          <InputGroup startElement={<SearchIcon />}>
            <Input value={searchTerm} onChange={handleSearchTerm} placeholder="Search" />
          </InputGroup>
          <InviteUser />
        </Stack>
      </Flex>
      <KDataTable centerColumns={TABLE_CENTER_HEADERS} headers={TABLE_HEADERS}>
        {filteredArray.length > 0 &&
          filteredArray.map(({ user_id, email, user_role, status }) => (
            <UserTableRow
              key={user_id}
              canUpdateRole={canChangeRoles && user?.userId !== user_id && status !== 'Pending'}
              email={email}
              role={user_role}
              status={status}
              userId={user_id}
              updateUserRole={mutate}
            />
          ))}
        {filteredArray.length === 0 && (
          <Table.Row>
            <Table.Cell colSpan={8} textAlign={'center'}>
              There are no users in this organization
            </Table.Cell>
          </Table.Row>
        )}
      </KDataTable>
    </>
  );
};
