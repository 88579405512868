import { createListCollection, Flex, Icon, Input, SimpleGrid, Skeleton, Stack, Text, Textarea } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrUpdateBusinessDetails, ORGANIZATION_SERVICE_QUERY_KEYS } from 'apis/organizations-apis';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useMemo, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { LocationOptionType } from 'types/location';
import { CountryCodeEnum, OrgDetailsInstance } from 'types/shared-types';
import { replaceNullWithEmptyString } from 'utils';
import { entityTypes } from 'utils/enum-helpers';
import { getMaxLengthMessage } from 'utils/utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  business_name: Yup.string().required('Business Name required').max(200, getMaxLengthMessage('Business Name', 200)),
  entity_type: Yup.string().required('Entity Type required'),
  dba: Yup.string().max(200, getMaxLengthMessage('Trade Name', 200)),
  incorporation_state: Yup.string()
    .required('Incorporation State required')
    .max(200, getMaxLengthMessage('Incorporation State', 200)),
  incorporation_country: Yup.string().required('Incorporation Country required'),
  ein: Yup.string().required('EIN required').max(200, getMaxLengthMessage('EIN', 200)),
  business_description: Yup.string()
    .required('Business Description required')
    .max(2000, getMaxLengthMessage('Business Description', 2000)),
  home_state_registration: Yup.string()
    .required('Home State Registration required')
    .max(200, getMaxLengthMessage('Home State Registration', 200)),
  first_operations_date: Yup.string().required('First Operations Date required'),
  company_address_1: Yup.string()
    .required('Company Address required')
    .max(200, getMaxLengthMessage('Company Address', 200)),
  company_city: Yup.string().required('Company City required').max(200, getMaxLengthMessage('Company City', 200)),
  company_country_code: Yup.string(),
  company_state: Yup.string().required('Company State required').max(200, getMaxLengthMessage('Company State', 200)),
  company_postal_code: Yup.string()
    .required('Company Postal Code required')
    .max(100, getMaxLengthMessage('Company Postal Code', 100)),
});

export const BUSINESS_DETAILS = [
  'business_name',
  'entity_type',
  'dba',
  'incorporation_state',
  'incorporation_country',
  'ein',
  'business_description',
  'home_state_registration',
  'first_operations_date',
  'company_address_1',
  'company_address_2',
  'company_country_code',
  'company_city',
  'company_state',
  'company_postal_code',
  'tax_ids',
];

const INITIAL_FORM_VALUE = {
  business_name: '',
  entity_type: '',
  dba: '',
  incorporation_state: '',
  incorporation_country: '',
  ein: '',
  business_description: '',
  home_state_registration: '',
  first_operations_date: '',
  company_address_1: '',
  company_address_2: '',
  company_city: '',
  company_state: '',
  company_postal_code: '',
  company_country_code: '',
  tax_ids: [],
};

/**
 * Handles the business details edit. step 1 of org-details.
 */
const BusinessDetailsEdit = ({
  orgDetails,
  goNext,
  isOrgDetailsLoading,
}: {
  orgDetails?: OrgDetailsInstance;
  goNext: () => void;
  isOrgDetailsLoading?: boolean;
}) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();
  const { countries, getStates } = useLocationData();

  const [incorporationStateOptions, setIncorporationStateOptions] = useState<LocationOptionType[]>([]);
  const [companyStateOptions, setCompanyStateOptions] = useState<LocationOptionType[]>([]);

  const { mutate: updateBusinessDetails, isPending } = useMutation({
    mutationFn: (payload: Partial<OrgDetailsInstance>) => {
      return addOrUpdateBusinessDetails(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)] });
      handleSuccessNotification('Business Information Updated successfully.');
      goNext();
    },
  });

  const { values, setFieldValue, handleChange, handleSubmit, isValid, dirty, errors, touched, setTouched } = useFormik({
    initialValues: {
      ...(replaceNullWithEmptyString(orgDetails) || INITIAL_FORM_VALUE),
      // Extract Canadian tax_id if it exists
      canada_business_number: orgDetails?.tax_ids?.find(tax => tax.country_code === CountryCodeEnum.CA)?.tax_id || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      const payload: {
        [key: string]: any;
      } = {};
      const val: {
        [key: string]: any;
      } = { ...values };

      if (values.canada_business_number) {
        payload.tax_id = values.canada_business_number;
      }

      BUSINESS_DETAILS.forEach(elem => {
        if (elem !== 'tax_ids') {
          // Skip tax_ids field
          payload[elem] = val[elem] == null ? '' : val[elem];
        }
      });
      updateBusinessDetails({
        ...payload,
      });
    },
  });

  const entityTypeCollection = useMemo(() => {
    return createListCollection({
      items: entityTypes,
    });
  }, [entityTypes]);

  useEffect(() => {
    const fetchStates = async () => {
      if (values.incorporation_country) {
        const states = await getStates(values.incorporation_country);
        setIncorporationStateOptions(states);
      }
      if (values.company_country_code) {
        const states = await getStates(values.company_country_code);
        setCompanyStateOptions(states);
      }
    };

    fetchStates();
  }, [getStates, values.incorporation_country, values.company_country_code]);

  if (isOrgDetailsLoading) {
    return <Skeleton height="100%" width="60.75rem" />;
  }

  return (
    <Stack width={'100%'}>
      <Text fontSize={'md'} fontWeight="500">
        Business Details
      </Text>
      <Text fontSize={'sm'} mb={2}>
        Manage basic company information required by the jurisdictions
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4}>
        <Field
          label="Legal Business Name"
          invalid={!!(errors.business_name && touched.business_name)}
          errorText={String(errors.business_name)}
          required
        >
          <Input
            id="business_name"
            type="business_name"
            name="business_name"
            value={values.business_name ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Entity Type"
          invalid={!!(errors.entity_type && touched.entity_type)}
          errorText={String(errors.entity_type)}
          required
        >
          <SelectRoot
            collection={entityTypeCollection}
            value={values.entity_type ? [values.entity_type] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('entity_type', value[0]);
            }}
          >
            <SelectTrigger>
              <SelectValueText />
            </SelectTrigger>
            <SelectContent>
              {entityTypeCollection.items.map(item => (
                <SelectItem key={item.value} item={item}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
        <Field
          label={
            <Flex alignItems={'center'} gap="1">
              <Text>Trade Name</Text>
              <Tooltip
                content={`A "Trade Name," or DBA (Doing Business As), is the public name a
                      business uses, different from its legal registered name. To find it,
                      check business registration documents, search the Secretary of
                      State's website, or review business licenses. Enter the Legal
                      Business Name as the Trade Name if they are the same.`}
              >
                <Icon color="#4B5169" size="sm">
                  <MdInfo />
                </Icon>
              </Tooltip>
            </Flex>
          }
          invalid={!!(errors.dba && touched.dba)}
          errorText={String(errors.dba)}
        >
          <Input id="dba" type="dba" name="dba" value={values.dba ?? ''} onChange={handleChange} />
        </Field>
        <Field
          label={
            <Flex alignItems={'center'} gap="1">
              <Text>Federal EIN</Text>
              <Tooltip
                content={`Please enter the unique business identification number assigned
                    by your country if your business is located outside of the
                    United States, equivalent to the Federal EIN in the United States.`}
              >
                <Icon color="#4B5169" size="sm">
                  <MdInfo />
                </Icon>
              </Tooltip>
            </Flex>
          }
          invalid={!!(errors.ein && touched.ein)}
          errorText={String(errors.ein)}
          required
        >
          <Input id="ein" type="ein" name="ein" value={values.ein ?? ''} onChange={handleChange} />
        </Field>
        <Field
          label="Incorporation Country"
          invalid={!!(errors.incorporation_country && touched.incorporation_country)}
          errorText={String(errors.incorporation_country)}
          required
        >
          <NativeSelectRoot>
            <NativeSelectField
              name="incorporation_country"
              placeholder="Select Country"
              onChange={handleChange}
              value={values.incorporation_country ?? ''}
            >
              {countries.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
        <Field
          label="Select Incorporation State"
          invalid={!!(errors.incorporation_state && touched.incorporation_state)}
          errorText={String(errors.incorporation_state)}
          required
        >
          <NativeSelectRoot>
            <NativeSelectField
              placeholder="Select Incorporation State"
              name="incorporation_state"
              onChange={handleChange}
              value={values.incorporation_state ?? ''}
            >
              {incorporationStateOptions.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
      </SimpleGrid>
      <Text fontSize={'md'} fontWeight="medium" mt={2}>
        Company Address
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap={4} mt={2}>
        <Field label="Country" required>
          <NativeSelectRoot>
            <NativeSelectField
              name="company_country_code"
              placeholder="Select Country"
              onChange={handleChange}
              value={values.company_country_code ?? ''}
            >
              {countries.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
        <Field
          label="State"
          invalid={!!(errors.company_state && touched.company_state)}
          errorText={String(errors.company_state)}
          required
        >
          <NativeSelectRoot>
            <NativeSelectField
              placeholder="Select State"
              name="company_state"
              onChange={handleChange}
              value={values.company_state ?? ''}
            >
              {companyStateOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
        <Field
          label="City"
          invalid={!!(errors.company_city && touched.company_city)}
          errorText={String(errors.company_city)}
          required
        >
          <Input
            id="company_city"
            type="company_city"
            name="company_city"
            value={values.company_city ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Postal Code"
          invalid={!!(errors.company_postal_code && touched.company_postal_code)}
          errorText={String(errors.company_postal_code)}
          required
        >
          <Input
            id="company_postal_code"
            type="company_postal_code"
            name="company_postal_code"
            value={values.company_postal_code ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Address 1"
          invalid={!!(errors.company_address_1 && touched.company_address_1)}
          errorText={String(errors.company_address_1)}
          required
        >
          <Input
            id="company_address_1"
            type="company_address_1"
            name="company_address_1"
            value={values.company_address_1 ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Address 2"
          invalid={!!(errors.company_address_2 && touched.company_address_2)}
          errorText={String(errors.company_address_2)}
        >
          <Input
            id="company_address_2"
            type="company_address_2"
            name="company_address_2"
            value={values.company_address_2 ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Describe products and services you sell"
          required
          invalid={!!(errors.business_description && touched.business_description)}
          errorText={String(errors.business_description)}
          w="100%"
          gridColumn={{ base: '1', sm: '1', md: '1/3' }}
        >
          <Textarea
            id="business_description"
            name="business_description"
            value={values.business_description ?? ''}
            onChange={handleChange}
          />
        </Field>
      </SimpleGrid>

      <SimpleGrid minChildWidth="sm" gap={4}>
        {values.company_country_code === CountryCodeEnum.CA && (
          <Field
            label={'Canada Business Number'}
            invalid={!!(errors.canada_business_number && touched.canada_business_number)}
            errorText={String(errors.canada_business_number)}
          >
            <Input
              id="canada_business_number"
              type="canada_business_number"
              name="canada_business_number"
              value={values.canada_business_number ?? ''}
              onChange={handleChange}
            />
          </Field>
        )}
        <Field
          tooltip="The 'Home State Business Registration Number' is a unique identifier assigned by the state for business-related tasks like tax filings and legal permits. You can find it on official documents, state's online business search, by contacting the business registration office, or on previous tax returns. Enter your Federal EIN if unavailable."
          label={'Home State Business Registration Number'}
          invalid={!!(errors.home_state_registration && touched.home_state_registration)}
          errorText={String(errors.home_state_registration)}
          required
        >
          <Input
            id="home_state_registration"
            type="home_state_registration"
            name="home_state_registration"
            value={values.home_state_registration ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Date of First Operations"
          invalid={!!(errors.first_operations_date && touched.first_operations_date)}
          errorText={String(errors.first_operations_date)}
          required
        >
          <DatePicker
            selected={values.first_operations_date ?? undefined}
            onChange={date => {
              setFieldValue('first_operations_date', date);
            }}
          />
        </Field>
      </SimpleGrid>
      <Flex justify={'flex-end'}>
        <Button
          loading={isPending}
          disabled={!isValid || !dirty}
          width={'7rem'}
          alignSelf={'top'}
          onClick={() => {
            if (isValid) {
              handleSubmit();
            } else {
              setTouched({
                business_name: true,
                entity_type: true,
                incorporation_state: true,
                incorporation_country: true,
                ein: true,
                business_description: true,
                home_state_registration: true,
                first_operations_date: true,
                company_address_1: true,
                company_city: true,
                company_state: true,
                company_postal_code: true,
              });
            }
          }}
        >
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessDetailsEdit;
